const listRouteView = [
  {
    to: { name: "home" },
    title: "Home",
    icon: "mdi-cast",
  },
  {
    to: { name: "history" },
    title: "History",
    icon: "mdi-clipboard-multiple-outline",
  },
  {
    to: { name: "setting" },
    title: "Setting",
    icon: "mdi-account-box-outline",
  },
];

export default listRouteView;
